import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/armeria/armeria/site/src/layouts/release-notes.tsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const ThankYou = makeShortcode("ThankYou");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p {...{
      "className": "date"
    }}>{`24th June 2021`}</p>


    <h2 {...{
      "id": "️-bug-fixes",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%EF%B8%8F-bug-fixes",
        "aria-label": "️ bug fixes permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`🛠️ Bug fixes`}</h2>
    <ul>
      <li parentName="ul">{`You no longer see `}<inlineCode parentName="li">{`ClassCastException`}</inlineCode>{` when `}<inlineCode parentName="li">{`EpollChannelOption.TCP_USER_TIMEOUT`}</inlineCode>{`
or `}<inlineCode parentName="li">{`IOUringChannelOption.TCP_USER_TIMEOUT`}</inlineCode>{` is automatically enabled. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3659"
        }}>{`#3659`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "type://ServerPort#hasProtocol(SessionProtocol):https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/ServerPort.html#hasProtocol(com.linecorp.armeria.common.SessionProtocol)"
        }}>{`type://ServerPort#hasProtocol(SessionProtocol)`}</a>{` doesn't return `}<inlineCode parentName="li">{`false`}</inlineCode>{` anymore when a user specifies
`}<inlineCode parentName="li">{`H1`}</inlineCode>{`, `}<inlineCode parentName="li">{`H2`}</inlineCode>{`, `}<inlineCode parentName="li">{`H1C`}</inlineCode>{` or `}<inlineCode parentName="li">{`H2C`}</inlineCode>{`. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3660"
        }}>{`#3660`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "type://JettyService:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/jetty/JettyService.html"
        }}>{`type://JettyService`}</a>{` and `}<a parentName="li" {...{
          "href": "type://TomcatService:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/tomcat/TomcatService.html"
        }}>{`type://TomcatService`}</a>{` now always set the `}<inlineCode parentName="li">{`host`}</inlineCode>{` header. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3660"
        }}>{`#3660`}</a></li>
      <li parentName="ul">{`You no longer see `}<inlineCode parentName="li">{`NoClassDefFoundError`}</inlineCode>{` when using Spring integration modules with Spring Boot 2.4. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3656"
        }}>{`#3656`}</a></li>
    </ul>
    <h2 {...{
      "id": "-thank-you",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#-thank-you",
        "aria-label": " thank you permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`🙇 Thank you`}</h2>
    <ThankYou usernames={['dgahn', 'ikhoon', 'minwoox', 'trustin']} mdxType="ThankYou" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      